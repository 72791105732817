<template>
  <div>
    <span v-show="show" id="filter-badge" class="advanced-filter-activated" v-b-tooltip="tooltipMessage"></span>
  </div>
</template>

<script>

export default {
  name: 'NotificationDot',
  props: {
    show: {
      type: Boolean,
      default: false
    },
    tooltipMessage: {
      type: String
    }
  }
}
</script>

<style lang="scss" scoped>
.advanced-filter-activated {
  position: absolute;
  top: -4px;
  right: 4px;
  background-color: var(--orange);
  border-radius: 50%;
  width: 10px;
  height: 10px;
}
</style>